import React from "react"
import SEO from "../../components/seo"
import { keyframes } from "@emotion/core"
// import Button from "../../components/button"
import Demand from "../../components/demand"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
import { vP } from "../../components/gutters"
// import SAM01 from "../../images/sam-it/SAM01.png"
import presets, { colors } from "../../utils/presets"
import { itemListProd } from "../../utils/sidebar/item-list"
import ScreenShot from "../../components/product/screenShot"
import typography, { rhythm, options, scale } from "../../utils/typography"
import Cards from "../../components/cards";
import MonitorCards from "../../components/monitorCards";
import Container from "../../components/container";
import { graphql } from "gatsby"
import ItsmCards from "../../components/itsmCards";

const EccItPage = (props) => {
  // console.log(props);
  const { edges } = props.data.allMarkdownRemark
  let _summary = edges.find(({node: item}) => item.frontmatter.menu === 'summary');
  _summary = _summary ? _summary.node : null;
  let _summaryCards = edges.filter(({node: item}) => item.frontmatter.menu === 'summary-card');
  _summaryCards = _summaryCards || [];
  let _screenshot = props.data.markdownRemark
  let _install = edges.find(({node: item}) => item.frontmatter.menu === 'install');
  _install = _install ? _install.node : null;
  // console.log(_install);
  return (
      <Layout location={props.location} itemList={itemListProd} contentTitle="功能介绍">
          <SEO title="ITOSS整体介绍" />
          {/* <div css={styles.backgroundWave}>
              <section css={styles.section}>
                  <div css={styles.title}>
                      <h2 css={{color: colors.skyDark}}>服务器与应用管理 WeADMIN ECC</h2>
                      <h1 css={{color: colors.skyDark}}>全面、深度的服务器与服务器应用监测管理</h1>
                      <Button secondary to="/download/" overrideCSS={{marginTop: `1rem`, marginLeft: `0rem`}} style={{textDecoration: 'none',}}>
                          资料与软件下载
                      </Button>
                  </div>
              </section>
          </div>
          <div css={{ ...styles.contentHeader, ...styles.sticky }}>
              <h2 css={styles.contentTitle}>
                  <span>
                      ITOSS整体介绍
                  </span>
              </h2>
          </div> */}
          <div id="summary" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>概述</h3>
                <div css={styles.summary}>
                    <img src={_summary.frontmatter.summaryImg.publicURL} alt="" css={styles.picture} />
                    <div css={styles.description}>
                        <div css={styles.font} dangerouslySetInnerHTML={{ __html: _summary.html }}></div>
                        {/* <h3><b>WeADMIN ECC基础设施监控系统</b></h3>
                        <p css={styles.font}>WeADMIN ECC 专注于对局域网、广域网和互联网上的各种异构网络基础架构及其应用的故障监测和性能管理，
                            是集中式、非代理、跨平台监测的系统管理软件，
                            可以对各种操作系统下的服务器、各厂家的网络设备、各类数据库、中间件、邮件系统、WEB系统、电子商务以及各种企业应用进行全面深入的监测管理。
                        </p> */}
                    </div>
                </div>
            </div>
          </div>
          <div id="question" className="anchor-point">
            <div css={styles.contact}>
                <div css={styles.cards} style={{borderTop: '1px solid #ede7f3'}}>
                  {_summaryCards
                    .map(({ node: card }) => {
                      return (
                        <div css={styles.position} key={card.id}>
                          <h4 css={styles.summaryTitle} style={{paddingTop: 15}}>{card.frontmatter.title}</h4>
                          <Container overrideCSS={{
                            padding: vP,
                            borderBottom: '1px solid #ede7f3',
                            [presets.Tablet]: {
                              borderBottom: 'none'
                            }
                          }}>
                            <div css={styles.font} dangerouslySetInnerHTML={{ __html: card.html }}></div>
                          </Container>
                        </div>
                      )
                    })
                  }
                </div>
            </div>
          </div>
          <div id="function" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>功能点介绍</h3>
                <div css={styles.cards} style={{ boxShadow: '0 1px 0 0 #f5f3f7'}}>
                    <Cards location={props.location} overrideTitleCSS={{fontSize: '1rem'}} overrideCardCSS={{fontSize: 14}} />
                </div>
            </div>
          </div>
          <div id="monitor" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>监测器介绍</h3>
                <div css={styles.cards} style={{ boxShadow: '0 1px 0 0 #f5f3f7'}}>
                    <MonitorCards overrideTitleCSS={{fontSize: '1rem'}} overrideCardCSS={{fontSize: 14}} />
                </div>
            </div>
          </div>
          <div id="screenShot" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>{_screenshot.frontmatter.title}</h3>
                <ScreenShot images={_screenshot.frontmatter.screentshots} />
            </div>
          </div>
          <div id="demand" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>{_install.frontmatter.title}</h3>
                <div css={styles.cards}>
                    <Demand demandData={_install} />
                </div>
            </div>
          </div>
          {/* <div id="itsm" className="anchor-point">
            <div css={styles.contact}>
                <h3 css={styles.summaryTitle}>运维工单流程</h3>
                <div css={styles.cards} style={{ boxShadow: '0 1px 0 0 #f5f3f7'}}>
                    <ItsmCards overrideTitleCSS={{fontSize: '1rem'}} overrideCardCSS={{fontSize: 14}} />
                </div>
            </div>
          </div> */}
          {/* <div css={styles.footer}>
            <Footer />
          </div> */}
      </Layout>
  )
}

const stripeColor = `255, 255, 255, 0.9`
const stripeSize = 15

const box = {
    background: colors.ui.whisper,
    border: `1px solid ${colors.ui.light}`,
    borderRadius: presets.radiusLg,
    padding: `${rhythm(1)} ${rhythm(1)} 0`,
}

const stripeAnimation = keyframes({
    "0%": { backgroundPosition: `${rhythm(stripeSize)} ${rhythm(stripeSize * 2)}`},
    "100%": { backgroundPosition: `0 0` },
})

const stripeBg = {
    animation: `${stripeAnimation} 10s linear infinite`,
    backgroundColor: 'aliceBlue',
    backgroundSize: `${rhythm(stripeSize)} ${rhythm(stripeSize)}`,
    backgroundImage: `linear-gradient(45deg, rgba(${stripeColor}) 25%, transparent 25%, transparent 50%, rgba(${stripeColor}) 50%, rgba(${stripeColor}) 75%, transparent 75%, transparent)`,
}

const styles = {
    backgroundWave: {
        ...box,
        ...stripeBg,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: typography.options.headerFontFamily.join(`,`),
    },
    cards: {
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    description: {
        flex: 1,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        "& p": {
          marginBottom: 0
        },
        padding: vP,
        [presets.Tablet]: {
          padding: 24,
        }
    },
    font: {
        fontSize: 14,
        // [presets.Desktop]: {
        //     fontSize: scale(1 / 100).fontSize,
        // },
        // [presets.VHd]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
        // [presets.VVHd]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        marginBottom: 0,
    },
    licence: {
        color: colors.skyDark,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        fontSize: `0.5rem`,
        marginLeft: `auto`,
        textAlign: `right`,
    },
    main:{
        display: `flex`,
        flexDirection: `row`,
        flexWrap: `wrap`,
        justifyContent: `space-between`,
    },
    picture: {
        flex: `0 0 96px`,
        height: `153.3px`,
        marginLeft: `0`,
        width: `237.3px`,
        marginBottom: 0,
        [presets.VHd]: {
            flex: `0 0 96px`,
            height: `206.6px`,
            marginLeft: `0`,
            width: `374.6px`,
        }
    },
    position: {
        boxSizing: `border-box`,
        transform: `translateZ(0)`,
        [presets.Tablet]: {
            flex: `0 0 auto`,
            maxWidth: `50%`,
            boxShadow: `0 1px 0 0 ${colors.ui.light}`,
            "&:nth-child(5),&:nth-child(6)": {
            boxShadow: `none`,
            },
            "&:nth-child(2n)": {
            borderLeft: `1px solid ${colors.ui.light}`,
            },
        },
        [presets.Hd]: {
            flex: `0 0 auto`,
            maxWidth: `50%`,
            minWidth: `50%`,
            borderLeft: `1px solid ${colors.ui.light}`,
            "&:nth-child(4)": {
            boxShadow: `none`,
            },
            "&:nth-child(3n+1)": {
            borderLeft: 0,
            },
        },
    },
    section: {
        borderRadius: presets.radiusLg,
        borderBottom: `1px solid ${'colors.ui.light'}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        flex: `1 1 100%`,
        fontFamily: options.headerFontFamily.join(`,`),
        marginTop: rhythm(1),
        padding: vP,
        textAlign: `left`,
        [presets.Tablet]: {
            marginTop: 50,
        },
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
    summary: {
        align: `left`,
        display: ``,
        [presets.Phablet]: {
            display: `flex`,
        }
    },
    summaryTitle: {
        margin: `0 0 10px 0`,
        padding: '0 15px 15px 15px',
        alignItems: `center`,
        borderBottom: `1px solid ${colors.ui.border}`,
        display: `flex`,
        flexDirection: `row`,
        // height: presets.headerHeight,
        // paddingLeft: `${rhythm(3 / 4)}`,
        // paddingRight: `${rhythm(3 / 4)}`,
        // paddingBottom: `0.85rem`,
        zIndex: 1,
    },
    title: {
        margin: `0 auto ${rhythm(2)}`,
        maxWidth: rhythm(50),
    },
}

export default EccItPage

export const pageQuery = graphql`
  query ProductEccQuery {
    allMarkdownRemark(
      sort: {fields: [frontmatter___order], order:ASC }
      filter: { frontmatter: { type: { eq: "product" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            menu
            summaryImg {
              name
              publicURL
            }
            requires {
              name
              value
            }
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { type: { eq: "product" }, menu: { eq: "screentshot" } }
    ) {
      frontmatter {
        title
        screentshots {
          name
          publicURL
        }
      }
    }
  }
`
